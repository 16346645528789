import React from 'react';

import ActionGroupDefault from '@ulta/core/components/ActionGroupDefault/ActionGroupDefault';
import ActionGroups from '@ulta/core/components/ActionGroups/ActionGroups';

/**
 * Function for getExternalAction
 * @function getExternalAction
 * @param { object }  - returns an object with the appropriate HTML markup for actionGroup
 */
export const getExternalAction = ( data ) => {
  const {
    actionGroup,
    actionGroups,
    alignment,
    isKnockoutLink
  } = data;

  const showActionGroupDefault = ( actionGroup?.action1?.label || actionGroup?.action2?.label );

  const showActionGroups = actionGroups?.actions[0]?.label;

  return (
    <>
      { showActionGroupDefault && !showActionGroups &&
        <ActionGroupDefault
          action1={ actionGroup?.action1 }
          action2={ actionGroup?.action2 }
          isKnockoutLink={ isKnockoutLink }
          style={ actionGroup?.style }
          type={ actionGroup?.type }
        />
      }
      { showActionGroups &&
        <ActionGroups
          actions={ actionGroups?.actions }
          alignment={ alignment }
          isKnockoutLink={ isKnockoutLink }
          style={ actionGroups?.style }
          types={ actionGroups?.types }
          variant={ actionGroups?.variant }
        />
      }
    </>
  );
};