/**
 * ActionGroupDefault Component
 * @module views/modules/ActionGroupDefault
 */

import React from 'react';

import PropTypes from 'prop-types';
import PaletteActionGroupDefault from 'web-palette/dist/components/ActionGroupDefault/ActionGroupDefault';
import * as paletteUtils from 'web-palette/dist/components/ActionGroupDefault/ActionGroupDefault';
import { actionFormatter } from 'web-palette/dist/util/actionFormatter/actionFormatter';

import { getButtonHtml } from '@ulta/core/utils/getButtonHtml/getButtonHtml';
import { getLinkHtml } from '@ulta/core/utils/getLinkHtml/getLinkHtml';

/**
 * Represents a ActionGroup component
 *
 * @method
 * @param {ActionGroupProps} props - React properties passed from composition
 * @returns ActionGroup
 */
export const ActionGroupDefault = function( props ){
  const {
    // Action objects
    action1,
    action2,
    // Styles set in Amplience
    style,
    type,
    // Generated styles based coming from parent components
    fullyClickableParent,
    isKnockoutLink
  } = props;

  if( !action1 && !action2 ){
    return null;
  }

  let formattedAction1 = {};
  let formattedAction2 = {};
  const variant = paletteUtils.getVariant( type );

  if( !!action1 && action1.label ){
    formattedAction1 = actionFormatter(
      { action: action1, style: variant.action1, isKnockoutLink: isKnockoutLink, fullyClickableParent: fullyClickableParent },
      { getButtonMarkup: getButtonHtml, getLinkMarkup: getLinkHtml } );
  }

  if( !!action2 && action2.label ){
    formattedAction2 = actionFormatter(
      { action: action2, style: variant.action2, isKnockoutLink: isKnockoutLink, fullyClickableParent: fullyClickableParent },
      { getButtonMarkup: getButtonHtml, getLinkMarkup: getLinkHtml } );
  }

  const PalActionGroup = PaletteActionGroupDefault.default || PaletteActionGroupDefault;

  return (
    <PalActionGroup
      type={ type }
      style={ style }
      externalAction1={ formattedAction1 }
      externalAction2={ formattedAction2 }
    />
  );
};

/**
 * Property type definitions
 * @typedef ActionGroupDefaultProps
 * @type {object}
 * @property {string} example - refactor or delete
 */
export const propTypes =  {
  /**
   * Sets the stylistic version of the action
   * - **primary:** Sets the action to a primary style
   * - **secondary:** Sets the action to a secondary style
   * - **tertiary:** Sets the action to a tertiary style
   * - **arrow:** Sets the link to a arrow style
   */
  type: PropTypes.oneOf( [
    'Primary Button/Secondary Button',
    'Primary Button/Tertiary Button',
    'Primary Button/Primary Link',
    'Secondary Button/Primary Link',
    'Primary Link/Primary Link',
    'Arrow Link'
  ] ),
  /**
   * Sets the stylistic version of the action
   * - **inline:** the actions are displayed in a row
   * - **stacked:** the actions are displayed in a column
   * - **stackedFullWidth:** the actions are displayed in a column- buttons are full width and links are centered.
   */
  style: PropTypes.oneOf( [
    'inline',
    'stackedDefault',
    'stackedFullWidth'
  ] ),
  /**
   * Sets the primary action (palette only, no business logic)
   */
  action1: PropTypes.object,
  /**
   * Sets the secondary action (palette only, no business logic)
   */
  action2: PropTypes.object,
  /**
   * Boolean if link text is white
   */
  isKnockoutLink: PropTypes.bool
};


ActionGroupDefault.propTypes = propTypes;

export default ActionGroupDefault;