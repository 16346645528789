import React from 'react';

import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';

/**
 * Function for UltaAsset
 * @function getUltaAssetHtml
 * @param { object }  - returns an object with the appropriate HTML markup for UltaAsset depending on screensize
 */
export const getUltaAssetHtml = ( data ) => {
  const {
    image,
    mobileImage,
    tabletImage,
    thumbnailImage,
    isTablet,
    isDesktop,
    isMobile
  } = data;

  /** Need to separate by screensize in order to load a new image when screensize changes */
  return (
    <>
      { isDesktop &&
        <UltaAsset
          { ...image }
        />
      }
      { isTablet &&
        <UltaAsset
          { ...tabletImage }
        />
      }
      { isMobile &&
        <UltaAsset
          { ...mobileImage }
        />
      }
      { thumbnailImage &&
        <UltaAsset
          { ...thumbnailImage }
        />
      }
    </>
  );
};